import React from 'react'
import Index from '../../../Index'

export default function TermsAndCondition() {
      return (
            <>
                  <Index.Box className="admin-dashboard-content">
                        <Index.Box className="admin-user-list-flex admin-page-title-main">
                              <Index.Typography className='admin-page-title' component='h2' variant='h2'>Terms & Condition</Index.Typography>
                        </Index.Box>
                        <Index.Box className="cms-page-content-main privacy-policy-page common-card">
                              
                        </Index.Box>
                  </Index.Box>
            </>
      )
}
