const ADMIN = "admin";
const COMMON = "common";
const USER = "user";
// const WALLET = "wallet";

export const api = {
  admin: {
    login: `${ADMIN}/login`,
    forgetPassword: `${ADMIN}/forgot-password`,
    verifyOtp: `${ADMIN}/verify-otp`,
    resendOtp: `${ADMIN}/resend-otp`,
    resetPassword: `${ADMIN}/reset-password`,
    getUserList: `${ADMIN}/get-user-list`,
    getUserListDetail: `${ADMIN}/get-user-details`,
    getAssetsList: `${ADMIN}/get-all-asset`,
    postAssetsAddEdit: `${ADMIN}/add-edit-asset`,
    deleteAssets: `${ADMIN}/delete-asset`,
    getCms: `${COMMON}/get-cms`,
    addEdittermsCondition: `${ADMIN}/add-edit-terms-and-condition`,
    addEditPrivacyPolicy: `${ADMIN}/add-edit-privacy-policy`,
    getAssetsListDetail: `${ADMIN}/get-asset`,
    updateProfile: `${ADMIN}/edit-profile`,
    changePassword: `${ADMIN}/change-password`,
    getCategoryList: `${COMMON}/get-all-category`,
    addEditCategory: `${ADMIN}/add-edit-category`,
    deleteCategory: `${ADMIN}/delete-category`,
    getBusinessList: `${COMMON}/get-all-business-type`,
    addEditBusiness: `${ADMIN}/add-edit-business-type`,
    deleteBusiness: `${ADMIN}/delete-business-type`,
    getKycList: `${ADMIN}/get-user-kyc-list`,
    approveRejectKyc: `${ADMIN}/approve-reject-kyc`,
    approveRejectKyb: `${ADMIN}/approve-reject-kyb`,
    getKybList: `${ADMIN}/get-user-kyb-list`,
    addEditFaq: `${ADMIN}/add-edit-faq`,
    getFaqList: `${COMMON}/get-all-faq`,
    deleteFaq: `${ADMIN}/delete-faq`,
    addEditContactUS: `${ADMIN}/save-contact-details`,
    getContactDetails: `${USER}/get-contact-details`,
    getColorsList: `${COMMON}/get-all-color`,
    addEditColor: `${ADMIN}/add-edit-color`,
    deleteColors: `${ADMIN}/delete-color`,
    getFontsList: `${COMMON}/get-all-font`,
    addEditFont: `${ADMIN}/add-edit-font`,
    deleteFonts: `${ADMIN}/delete-font`,
    getQR: `${ADMIN}/get-request-payment-details`,
    getOnfidoToken: `${USER}/get-applicant-token`,
    getSumsubToken : `${COMMON}/get-access-token`,
    getApplicantDetails : `${ADMIN}/get-applicant-details`,
    getDashboardData:`${ADMIN}/get-dashboard-data`,
    approveRejectKycKyb: `${ADMIN}/approve-reject-kyc-and-kyb`,
    getWeeklyRegiteredUser:`${ADMIN}/get-weekly-registration`,
    getStoreListFromCategory:`${ADMIN}/get-store-list-from-category`
  },
};
